import React from 'react'
import {FaFacebookF} from 'react-icons/fa'
import {FiInstagram} from 'react-icons/fi'
import {FaTripadvisor} from 'react-icons/fa'
import './IconFooter.style.css'

const IconFooter = () => {

    const url ={
        facebook:"https://www.facebook.com/pages/category/Hotel-Resort/Quetzal-Dorado-Eco-Lodge-718993478241333/",
        instagram:"https://www.instagram.com/quetzaldoradoecolodge/?hl=es-la",
        tripadvsor:"https://www.tripadvisor.co/Hotel_Review-g6369108-d13800683-Reviews-Quetzal_Dorado_Eco_Lodge-Los_Naranjos_Tayrona_National_Park_Santa_Marta_Municipality.html"
    }

    return (
        <div className="center">
            <div className="social-footer">
                <a href={url.facebook}>
                    <FaFacebookF/>
                </a>
                <a href={url.instagram}>
                    <FiInstagram />
                </a>
                <a href={url.tripadvsor}>
                    <FaTripadvisor/>
                </a>
            </div>
        </div>
    )
}

export default IconFooter
