import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import NavbarWrapper, {
  Container,
  LogoArea,
  MenuArea,
  AvatarWrapper,
  AuthWrapper,
  MenuWrapper,
} from './Navbar.style';
import './switch.css'
const Navbar = ({
  className,
  logo,
  avatar,
  navMenu,
  authMenu,
  profileMenu,
  isLogin,
  headerType,
  searchComponent,
  location,
  searchVisibility,
}) => {
  const [language, setLanguage] = useState(true)
  const addAllClasses = ['navbar'];
  if (className) {
    addAllClasses.push(className);
  }
  if (headerType) {
    addAllClasses.push(`is_${headerType}`);
  }

  const getLanguage = () => {
    setLanguage(!language)
    return language ? 'es|es' : 'es|en'
  }

  return (
    <NavbarWrapper className={addAllClasses.join(' ')}>
      <Container>
        {logo || searchVisibility ? (
          <LogoArea>
            {logo}
          </LogoArea>
        ) : null}
        <MenuArea>
          {navMenu && (
            <MenuWrapper className="main_menu">{navMenu}</MenuWrapper>
          )}
        </MenuArea>
        <div>
          <div className="switch-button">
            <input onClick={() => setLanguage(!language)} type="checkbox" name="switch-button" id="switch-label" className="switch-button__checkbox" />
            {
              language
                ? <label onClick={window.doGTranslate.bind(this, 'es|en')} for="switch-label" className="switch-button__label"></label>
                : <label onClick={window.doGTranslate.bind(this, 'es|es')} for="switch-label" className="switch-button__label"></label>
            }
          </div>
          <div id="google_translate_element2"></div>
        </div>
      </Container>
    </NavbarWrapper>
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  navMenu: PropTypes.element,
  avatar: PropTypes.element,
  authMenu: PropTypes.element,
  isLogin: PropTypes.bool,
  headerType: PropTypes.oneOf(['transparent', 'default']),
};

export default Navbar;
