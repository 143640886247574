import { QUETZAL_PAGE, HOME_PAGE, ROOMS_PAGE, GALLERY_PAGE, CONTACT_PAGE, TOURISM_PAGE } from "settings/constant";

export const itemList = [
    {
        name: "Inicio",
        link: HOME_PAGE,
        exact: true,
    },
    {
        name: "Habitaciones",
        link: ROOMS_PAGE,
        exact: false,
    },
    {
        name: "El Quetzal",
        link: QUETZAL_PAGE,
        exact: false,
    },
    {
        name: "Galería",
        link: GALLERY_PAGE,
        exact: false,
    },
    {
        name: "Ubicación",
        link: TOURISM_PAGE,
        exact: false,
    },
    {
        name: "Contacto",
        link: CONTACT_PAGE,
        exact: false,
    }

]