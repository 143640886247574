import React from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';

import {
  HOME_PAGE,
  ROOMS_PAGE,
  QUETZAL_PAGE,
  GALLERY_PAGE,
  TOURISM_PAGE,
} from 'settings/constant';
import { itemList } from './item';

const MainMenu = ({ className }) => {
  return (
    <Menu className={className}>
      {
        itemList.map((item, key) => <Menu.Item key={`${key}`}>
          <NavLink exact={item.exact} to={`${item.link}`}>
            {item.name}
          </NavLink>
        </Menu.Item>
        )
      }
    </Menu>
  );
};

export default MainMenu;
