import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu } from 'antd';
import {
  HOME_PAGE,
  ROOMS_PAGE,
  QUETZAL_PAGE,
  GALLERY_PAGE,
  TOURISM_PAGE,
  CONTACT_PAGE
} from 'settings/constant';

const MobileMenu = ({ className }) => {
  return (
    <Menu className={className}>
      <Menu.Item key="0">
        <NavLink exact to={HOME_PAGE}>Inicio</NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={ROOMS_PAGE}>Habitaciones</NavLink>
      </Menu.Item>
      <Menu.Item key="2">
        <NavLink to={QUETZAL_PAGE}>El Quetzal</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={GALLERY_PAGE}>Galería</NavLink>
      </Menu.Item>
      <Menu.Item key="4">
        <NavLink to={TOURISM_PAGE}>Ubicación</NavLink>
      </Menu.Item>
      <Menu.Item key="5">
        <NavLink to={CONTACT_PAGE}>Contacto</NavLink>
      </Menu.Item>
    </Menu>
  );
};

export default MobileMenu;
