// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const ROOMS_PAGE = "/rooms";
export const SINGLE_POST_PAGE = "/post";

// Agent Profile Page Section
export const QUETZAL_PAGE = "/quetzal";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account-settings";
export const AGENT_PROFILE_EDIT_PAGE = "/edit";
export const AGENT_IMAGE_EDIT_PAGE = "/change-image";
export const AGENT_PASSWORD_CHANGE_PAGE = "/change-password";
export const AGENT_PROFILE_DELETE = "/delete";
export const AGENT_PROFILE_FAVOURITE = "/favourite-post";
export const AGENT_PROFILE_CONTACT = "/contact";

// Other Pages
export const GALLERY_PAGE = "/gallery";
export const CONTACT_PAGE = "/contact";
export const PRIVACY_PAGE = "/privacy";
export const ADD_HOTEL_PAGE = "/add-hotel";
export const TOURISM_PAGE = "/tourism";
export const MAPA_TAYRONA_PAGE = "/mapa-tayrona";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";
