import React, { useContext } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { AuthContext } from "./context/AuthProvider";
import Layout from "./container/Layout/Layout";
import {
  LOGIN_PAGE,
  HOME_PAGE,
  ROOMS_PAGE,
  SINGLE_POST_PAGE,
  TOURISM_PAGE,
  ADD_HOTEL_PAGE,
  QUETZAL_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  GALLERY_PAGE,
  CONTACT_PAGE,
  MAPA_TAYRONA_PAGE,
} from "./settings/constant";

/**
 *
 * Public Routes
 *
 */
const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Home" */ "./container/Home/Home"),
      loading: Loading,
      modules: ["Home"],
    }),
    exact: true,
  },
  {
    path: LOGIN_PAGE,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SignIn" */ "./container/Auth/SignIn/SignIn"
        ),
      loading: Loading,
      modules: ["SignIn"],
    }),
  },
  {
    path: `${ROOMS_PAGE}/:slug`,
    component: Loadable({
      loader: () =>
        import(
          /* webpackChunkName: "SinglePageView" */ "./container/Rooms/SinglePage/SinglePageView"
        ),
      loading: Loading,
      modules: ["SinglePageView"],
    }),
  },
  {
    path: ROOMS_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Listing" */ "./container/Rooms/Listing"),
      loading: Loading,
      modules: ["Listing"],
    }),
  },
  {
    path: QUETZAL_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "privacy" */ "./container/Privacy/Privacy"),
      loading: Loading,
      modules: ["AgentDetailsViewPage"],
    }),
  },
  {
    path: GALLERY_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/Gallery"),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
  {
    path: TOURISM_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Tourism" */ "./container/Tourism/Tourism"),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
  {
    path: MAPA_TAYRONA_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/MapaTayrona"),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
  {
    path: CONTACT_PAGE,
    component: Loadable({
      loader: () =>
        import(/* webpackChunkName: "Pricing" */ "./container/Contact"),
      loading: Loading,
      modules: ["Pricing"],
    }),
  },
];

/**
 *
 * Protected Route Component
 *
 */
const AddListing = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AddListing" */ "./container/AddListing/AddListing"
    ),
  loading: Loading,
  modules: ["AddListing"],
});

const AgentAccountSettingsPage = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AgentAccountSettingsPage" */ "./container/Agent/AccountSettings/AgentAccountSettingsPage"
    ),
  loading: Loading,
  modules: ["AgentAccountSettingsPage"],
});

/**
 *
 * Not Found Route Component
 *
 */

const NotFound = Loadable({
  loader: () =>
    import(/* webpackChunkName: "NotFound" */ "./container/404/404"),
  loading: Loading,
  modules: ["NotFound"],
});

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { loggedIn } = useContext(AuthContext);
  return (
    <Route
      render={(props) =>
        loggedIn ? <Component {...props} /> : <Redirect to={LOGIN_PAGE} />
      }
      {...rest}
    />
  );
};

/**
 *
 * Overall Router Component
 *
 */

const Routes = () => {
  return (
    <Layout>
      <Switch>
        {routes.map(({ path, component, exact = false }) => (
          <Route key={path} path={path} exact={exact} component={component} />
        ))}
        <ProtectedRoute path={ADD_HOTEL_PAGE} component={AddListing} />
        <ProtectedRoute
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          component={AgentAccountSettingsPage}
        />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  );
};

export default Routes;
